import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from 'react-bootstrap';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CryptoJS from "crypto-js";
import { backOfficeLoginRequest } from "../actions/backOfficeAuthAction";

const BackOfficeLogin = ({history}) => {
    // Initialize react state
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const form = useRef();

    // Read Redux state
    const dispatch = useDispatch();
    const backofficeAuthDetails = useSelector(
        (state) => state.backofficeAuthState
    );
    const {loginSucceeded, error} = backofficeAuthDetails;

    useEffect(() => {
        if (loginSucceeded) {
            history.push("/backoffice");
        }
    }, [history, loginSucceeded]);

    const onSubmitHandler = (e) => {
        e.preventDefault();

        if (username && password) {
            const userId = username;
            const id = "" + CryptoJS.SHA256(userId);
            dispatch(backOfficeLoginRequest(username, password, id));
        }
    };

    return (
        <Container fluid>
            <div className="card card-container backoffice-login-card-container">
                <h2 className="backoffice-title">Login Page</h2>
                <img
                    src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                    alt="profile-img"
                    className="profile-img-card backoffice-login-img"
                />

                <Form onSubmit={(e) => onSubmitHandler(e)} ref={form} className="gcotg-form">
                    <div>
                        <div className="form-group gcotg-form-group">
                            <label htmlFor="username">Username</label>
                            <Input
                                type="text"
                                className="form-control gcotg-form-element"
                                name="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>

                        <div className="form-group gcotg-form-group">
                            <label htmlFor="password">Password</label>
                            <Input
                                type="password"
                                className="form-control gcotg-form-element"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>

                        <div className="form-group gcotg-form-group">
                            <button className="btn btn-primary btn-block gcotg-button">Log In</button>
                        </div>
                    </div>
                </Form>
                {error && (
                    <div className="gcotg-error-message">
                        <div role="alert">{error}</div>
                    </div>
                )}
            </div>
        </Container>
    );
};

export default BackOfficeLogin;
