import React from "react";
import { Button, Modal } from "react-bootstrap";

/**
 * A modal dialog with a warning the user they will be logged out soon.
 * This dialog should be used in conjunction with the useIdleTimeout hook,
 * which detects
 * @param show - Boolean state indicating if the modal should be displayed
 * @param handleStay - Function to call when the user clicks the "Stay In" button
 * @param handleLogout - Function to call when the user clicks the "Log Out" button
 * @returns {Element} - A modal dialog to display when the user is idle
 * @constructor
 */
const IdleOverLay = ({ show, handleStay, handleLogout }) => {
  return (
    <>
      <Modal show={show} onHide={handleStay} closebutton>
        <Modal.Header closebutton>
          <div>Session Timeout Warning</div>
        </Modal.Header>
        <Modal.Body>
          <div>
            Your session will expire shortly. Do you want to stay signed in?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="gcotg-button" onClick={handleStay}>
            Stay In
          </Button>
          <Button className="gcotg-button" onClick={handleLogout}>
            Log Out
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default IdleOverLay;
