import * as boConstants from "../constants/backOfficeActions.js";
import {
  BACK_OFFICE_SET_BIRTHDAY_FAIL,
  BACK_OFFICE_SET_BIRTHDAY_REQUEST,
  BACK_OFFICE_SET_BIRTHDAY_SUCCESS
} from "../constants/backOfficeActions.js";

const initialState = {
  loading: false,
  networkFail: false,
  propertyList: null,
  error: null,
  catalogLoading: false,
  catalogMessage: null,
  catalogError: null,
  offerRetryLoading: false,
  offerRetryMessage: null,
  offerRetryError: null,
  cancelRetryLoading: false,
  cancelRetryMessage: null,
  cancelRetryError: null,
  getFailedOffersLoading: false,
  getFailedOffersMessage: null,
  getFailedOffersError: null,
  getProblemOffersLoading: false,
  problemOffersList: null,
  getProblemOffersError: null,
  getLoadsLoading: false,
  getLoadsList: null,
  getLoadsError: null,
  playerOffersLoading: false,
  playerOffersList: null,
  playerOffersError: null,
  forceClaimLoading: false,
  forceClaimResponse: null,
  forceClaimError: null,
  getPlayerProfileProcessing: false,
  playerProfile: null,
  getPlayerProfileError: null,
  cancelOfferProcessing: false,
  cancelOfferResponse: null,
  cancelOfferError: null,
  getIncompleteOffersLoading: false,
  incompleteOffersList: null,
  getIncompleteOffersError: null,
  brandCatalogLoading: false,
  brandCatalogList: null,
  brandCatalogError: null,
  reactivatePlayerProcessing: false,
  reactivatePlayerResult: null,
  reactivatePlayerError: null,
  setBirthdayProcessing: false,
  setBirthdayError: null,
};

export const backOfficeReducer = (state = initialState, action) => {
  switch (action.type) {
    case boConstants.BACK_OFFICE_PROPERTY_LIST_REQUEST:
      return { ...state, loading: true };
    case boConstants.BACK_OFFICE_PROPERTY_LIST_SUCCESS:
      return {
        ...state,
        networkFail: false,
        loading: false,
        propertyList: action.payload,
      };
    case boConstants.BACK_OFFICE_PROPERTY_LIST_FAIL:
      return {
        ...state,
        networkFail: true,
        loading: false,
        error: action.payload,
      };
    case boConstants.BACK_OFFICE_CATALOG_REQUEST:
      return {
        ...state,
        catalogLoading: true,
        catalogMessage: null,
        catalogError: null,
      };
    case boConstants.BACK_OFFICE_CATALOG_SUCCESS:
      return {
        ...state,
        catalogLoading: false,
        catalogMessage: action.payload,
        catalogError: null,
      };
    case boConstants.BACK_OFFICE_CATALOG_FAIL:
      return {
        ...state,
        catalogLoading: false,
        catalogMessage: null,
        catalogError: action.error,
      };
    case boConstants.BACK_OFFICE_GET_BRAND_CATALOG_REQUEST:
      return {
        ...state,
        brandCatalogLoading: true,
        brandCatalogList: null,
        brandCatalogError: null,
      };
    case boConstants.BACK_OFFICE_GET_BRAND_CATALOG_SUCCESS:
      return {
        ...state,
        brandCatalogLoading: false,
        brandCatalogList: action.payload,
        brandCatalogError: null,
      };
    case boConstants.BACK_OFFICE_GET_BRAND_CATALOG_FAIL:
      return {
        ...state,
        brandCatalogLoading: false,
        brandCatalogList: null,
        brandCatalogError: action.error,
      };
    case boConstants.BACK_OFFICE_RETRY_OFFER_REQUEST:
      return {
        ...state,
        offerRetryLoading: true,
        offerRetryMessage: null,
        offerRetryError: null,
      };
    case boConstants.BACK_OFFICE_RETRY_OFFER_SUCCESS:
      return {
        ...state,
        offerRetryLoading: false,
        offerRetryMessage: action.payload,
        offerRetryError: null,
      };
    case boConstants.BACK_OFFICE_RETRY_OFFER_FAIL:
      return {
        ...state,
        offerRetryLoading: false,
        offerRetryMessage: null,
        offerRetryError: action.error,
      };
    case boConstants.BACK_OFFICE_RESET_REQUEST:
      return {};
    case boConstants.BACK_OFFICE_CANCEL_RETRY_REQUEST:
      return {
        ...state,
        cancelRetryLoading: true,
        cancelRetryMessage: null,
        cancelRetryError: null,
      };
    case boConstants.BACK_OFFICE_CANCEL_RETRY_SUCCESS:
      return {
        ...state,
        cancelRetryLoading: false,
        cancelRetryMessage: action.payload,
        cancelRetryError: null,
      };
    case boConstants.BACK_OFFICE_CANCEL_RETRY_FAIL:
      return {
        ...state,
        cancelRetryLoading: false,
        cancelRetryMessage: null,
        cancelRetryError: action.error,
      };
    case boConstants.BACK_OFFICE_GET_FAILED_OFFERS_REQUEST:
      return {
        ...state,
        getFailedOffersLoading: true,
        getFailedOffersMessage: null,
        getFailedOffersError: null,
      };
    case boConstants.BACK_OFFICE_GET_FAILED_OFFERS_SUCCESS:
      return {
        ...state,
        getFailedOffersLoading: false,
        getFailedOffersMessage: action.payload,
        getFailedOffersError: null,
      };
    case boConstants.BACK_OFFICE_GET_FAILED_OFFERS_FAIL:
      return {
        ...state,
        getFailedOffersLoading: false,
        getFailedOffersMessage: null,
        getFailedOffersError: action.error,
      };
    case boConstants.BACK_OFFICE_GET_INCOMPLETE_OFFERS_REQUEST:
      return {
        ...state,
        getIncompleteOffersLoading: true,
        incompleteOffersList: null,
        getIncompleteOffersError: null,
      };
    case boConstants.BACK_OFFICE_GET_INCOMPLETE_OFFERS_SUCCESS:
      return {
        ...state,
        getIncompleteOffersLoading: false,
        incompleteOffersList: action.payload,
        getIncompleteOffersError: null,
      };
    case boConstants.BACK_OFFICE_GET_INCOMPLETE_OFFERS_FAIL:
      return {
        ...state,
        getIncompleteOffersLoading: false,
        incompleteOffersList: null,
        getIncompleteOffersError: action.error,
      };
    case boConstants.BACK_OFFICE_GET_LOADS_REQUEST:
      return {
        ...state,
        getLoadsLoading: true,
        getLoadsList: null,
        getLoadsError: null,
      };
    case boConstants.BACK_OFFICE_GET_LOADS_SUCCESS:
      return {
        ...state,
        getLoadsLoading: false,
        getLoadsList: action.payload,
        getLoadsError: null,
      };
    case boConstants.BACK_OFFICE_GET_LOADS_FAIL:
      return {
        ...state,
        getLoadsLoading: false,
        getLoadsList: null,
        getLoadsError: action.error,
      };
    case boConstants.BACK_OFFICE_GET_PLAYER_OFFERS_REQUEST:
      return {
        ...state,
        playerOffersLoading: true,
        playerOffersList: null,
        playerOffersError: null,
      };
    case boConstants.BACK_OFFICE_GET_PLAYER_OFERS_SUCCESS:
      return {
        ...state,
        playerOffersLoading: false,
        playerOffersList: action.payload,
        playerOffersError: null,
      };
    case boConstants.BACK_OFFICE_GET_PLAYER_OFFERS_FAIL:
      return {
        ...state,
        playerOffersLoading: false,
        playerOffersList: null,
        playerOffersError: action.error,
      };
    case boConstants.BACK_OFFICE_FORCE_CLAIM_REQUEST:
      return {
        ...state,
        forceClaimLoading: true,
        forceClaimResponse: null,
        forceClaimError: null,
      };
    case boConstants.BACK_OFFICE_FORCE_CLAIM_SUCCESS:
      return {
        ...state,
        forceClaimLoading: false,
        forceClaimResponse: action.payload,
        forceClaimError: null,
      };
    case boConstants.BACK_OFFICE_FORCE_CLAIM_FAIL:
      return {
        ...state,
        forceClaimLoading: false,
        forceClaimResponse: null,
        forceClaimError: action.error,
      };
    case boConstants.BACK_OFFICE_CANCEL_OFFER_REQUEST:
      return {
        ...state,
        cancelOfferProcessing: true,
        cancelOfferResponse: null,
        cancelOfferError: null,
      };
    case boConstants.BACK_OFFICE_CANCEL_OFFER_SUCCESS:
      return {
        ...state,
        cancelOfferProcessing: false,
        cancelOfferResponse: action.payload,
        cancelOfferError: null,
      };
    case boConstants.BACK_OFFICE_CANCEL_OFFER_FAIL:
      return {
        ...state,
        cancelOfferProcessing: false,
        cancelOfferResponse: null,
        cancelOfferError: action.error,
      };
    case boConstants.BACK_OFFICE_GET_PLAYER_PROFILE_REQUEST:
      return {
        ...state,
        getPlayerProfileProcessing: true,
        playerProfile: null,
        getPlayerProfileError: null,
      };
    case boConstants.BACK_OFFICE_GET_PLAYER_PROFILE_SUCCESS:
      return {
        ...state,
        getPlayerProfileProcessing: false,
        playerProfile: action.payload,
        getPlayerProfileError: null,
      };
    case boConstants.BACK_OFFICE_GET_PLAYER_PROFILE_FAIL:
      return {
        ...state,
        getPlayerProfileProcessing: false,
        playerProfile: null,
        getPlayerProfileError: action.error,
      };
    case boConstants.BACK_OFFICE_REACTIVATE_PLAYER_REQUEST:
      return {
        ...state,
        reactivatePlayerProcessing: true,
        reactivatePlayerResult: null,
        reactivatePlayerError: null,
      };
    case boConstants.BACK_OFFICE_REACTIVATE_PLAYER_SUCCESS:
      return {
        ...state,
        reactivatePlayerProcessing: false,
        reactivatePlayerResult: action.payload,
        reactivatePlayerError: null,
      };
    case boConstants.BACK_OFFICE_REACTIVATE_PLAYER_FAIL:
      return {
        ...state,
        reactivatePlayerProcessing: false,
        reactivatePlayerResult: null,
        reactivatePlayerError: action.error,
      };
    case boConstants.BACK_OFFICE_SET_BIRTHDAY_REQUEST:
      return {
        ...state,
        setBirthdayProcessing: true,
      };
    case boConstants.BACK_OFFICE_SET_BIRTHDAY_SUCCESS:
      return {
        ...state,
        setBirthdayProcessing: false,
        playerProfile: action.payload,
      };
    case boConstants.BACK_OFFICE_SET_BIRTHDAY_FAIL:
      return {
        ...state,
        setBirthdayProcessing: false,
        setBirthdayError: action.error,
      };
    case boConstants.BACK_OFFICE_SET_ZIPCODE_REQUEST:
      return {
        ...state,
        setZipcodeProcessing: true,
      };
    case boConstants.BACK_OFFICE_SET_ZIPCODE_SUCCESS:
      return {
        ...state,
        setZipcodeProcessing: false,
        playerProfile: action.payload,
      };
    case boConstants.BACK_OFFICE_SET_ZIPCODE_FAIL:
      return {
        ...state,
        setZipcodeProcessing: false,
        setBirthdayError: action.error,
      };
    default:
      return state;
  }
};
