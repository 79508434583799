import {Pagination} from "react-bootstrap";
import React from "react";
import {useDispatch} from "react-redux";

// The PaginationComponent is a reusable component that displays a Bootstrap Pagination.
// It requires the following props:
// currentPage - the current page number
// pageSize - the number of items per page
// totalPages - the total number of pages
// pageFetchHandler - the function to dispatch when a new page is requested
const PaginationComponent = ({currentPage, pageSize, totalPages, pageFetchHandler, ...rest}) => {

  const dispatch = useDispatch();

  const prevPageHandler = (pageFetchHandler) => {
    // Only fetch the previous page if the current page is not the first page
    if(currentPage !== 0) {
      dispatch(pageFetchHandler(currentPage - 1, pageSize, {...rest}));
    }
  };

  const nextPageHandler = (pageFetchHandler) => {
    // Only fetch the next page if the current page is not the last page
    if(currentPage !== totalPages - 1) {
      dispatch(pageFetchHandler(currentPage + 1, pageSize, {...rest}));
    }
  };

  return (
    <div className="pagination-div">
      <Pagination className="gcotg-pagination" size="sm">
        <Pagination.Prev
          onClick={() => prevPageHandler(pageFetchHandler)}
          disabled={currentPage === 0}/>
        <p className="pagination-description">
          Page {currentPage + 1} of {totalPages}
        </p>
        <Pagination.Next
          onClick={() => nextPageHandler(pageFetchHandler)}
          disabled={currentPage === totalPages - 1}/>
      </Pagination>
    </div>
  );
};

export default PaginationComponent;