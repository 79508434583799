import {Alert, Button, Form, Row} from "react-bootstrap";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getPlayerProfile, reactivatePlayer, setBirthday, setZipcode} from "../actions/backOfficeAction";
import InputMask from "react-input-mask";

const PlayerProfileDetails = ({playerProfile}) => {

  const dispatch = useDispatch();

  const [editingBirthday, setEditingBirthday] = useState(false);
  const [newBirthday, setNewBirthday] = useState(playerProfile?.dob);
  const [editingZipcode, setEditingZipcode] = useState(false);
  const [newZipcode, setNewZipcode] = useState(playerProfile?.zipcode);

  const reactivatePlayerProcessing = useSelector(
    (state) => state.backOfficeState.reactivatePlayerProcessing
  );
  const reactivatePlayerResult = useSelector(
    (state) => state.backOfficeState.reactivatePlayerResult
  );
  const reactivatePlayerError = useSelector(
    (state) => state.backOfficeState.reactivatePlayerError
  );

  async function reactivateClickedHandler(rewardsId) {
    console.log("Reactivate clicked for player ID: ", rewardsId);
    await dispatch(reactivatePlayer(rewardsId));
    dispatch(getPlayerProfile(rewardsId));
  }

  // Called when the edit birthday button is clicked
  function editBirthdayClickedHandler() {
    // Set the editingBirthday state to true to show the editing form
    setEditingBirthday(true);
  }

  // Called when the save button is clicked after editing the birthday
  function saveBirthdayHandler() {
    console.log("New birthday: ", newBirthday);
    setEditingBirthday(false);
    dispatch(setBirthday(playerProfile?.rewardsId, newBirthday));
  }

  // Called when the cancel button is clicked after editing the birthday
  function cancelBirthdayHandler() {
    console.log("Cancel birthday: ", newBirthday);
    setEditingBirthday(false);
  }

  // Called when the edit zipcode button is clicked
  function editZipcodeClickedHandler() {
    // Set the editingZipcode state to true to show the editing form
    setEditingZipcode(true);
  }

  // Called when the save button is clicked after editing the zip code
  function saveZipcodeHandler() {
    console.log("New ZIP code: ", newZipcode);
    setEditingZipcode(false);
    dispatch(setZipcode(playerProfile?.rewardsId, newZipcode));
  }

  // Called when the cancel button is clicked after editing the zip code
  function cancelZipcodeHandler() {
    console.log("Cancel zip code: ", newZipcode);
    setEditingZipcode(false);
  }

  return (
    <div className="admin-screen-divider-div player-profile-details">
      <h4 className="player-profile-details-header">
        {playerProfile?.firstName} {playerProfile?.lastName}, Player ID: {playerProfile?.rewardsId}
      </h4>
      <div className="player-profile-details">
        <div className="edit-in-place-form-field">
          {editingBirthday ? (
            <>
              <Form.Group as={Row} controlId="formBasicPassword">
                <Form.Label className="gcotg-form-label">
                  Birthday(MM/DD/YYYY)
                </Form.Label>
                <Form.Control
                  as={InputMask}
                  mask="99/99/9999"
                  placeholder="mm/dd/yyyy"
                  value={newBirthday}
                  onChange={(e) => setNewBirthday(e.target.value)}
                  className="form-control gcotg-form-element"
                />
              </Form.Group>
              <Button
                variant="primary"
                size="sm"
                className="in-place-form-button-secondary"
                onClick={() => cancelBirthdayHandler()}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                size="sm"
                className="in-place-form-button-primary"
                onClick={() => saveBirthdayHandler()}
              >
                Save New Birthday
              </Button>
            </>
          ) : (
            <>
              <span>Birthday: {playerProfile?.dob} </span>
              <Button variant="primary" size="sm" className="gcotg-button"
                      onClick={() => editBirthdayClickedHandler()}>
                Edit Birthday
              </Button>
            </>
          )}
        </div>
        <div className="edit-in-place-form-field">
          {editingZipcode ? (
            <>
              <Form.Group as={Row} controlId="formBasicPassword">
                <Form.Label className="gcotg-form-label">ZIP Code</Form.Label>
                <Form.Control
                  placeholder="ZIP Code"
                  value={newZipcode}
                  onChange={(e) => setNewZipcode(e.target.value)}
                  className="form-control gcotg-form-element login-zipcode-form-element"
                />
              </Form.Group>
              <Button
                variant="primary"
                size="sm"
                className="in-place-form-button-secondary"
                onClick={() => cancelZipcodeHandler()}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                size="sm"
                className="in-place-form-button-primary"
                onClick={() => saveZipcodeHandler()}
              >
                Save New ZIP code
              </Button>
            </>
          ) : (
            <>
              <span>ZIP code: {playerProfile?.zipcode} </span>
              <Button variant="primary" size="sm" className="gcotg-button"
                      onClick={() => editZipcodeClickedHandler()}>
                Edit ZIP code
              </Button>
            </>
          )}
        </div>
        {playerProfile?.disabled &&
          <Alert variant='warning'>
            {playerProfile?.disabled ? "LOGIN DISABLED" : ""}
            <span className="reactivate-button-span">
                <Button variant="primary" size="sm" className="gcotg-button"
                        onClick={() => reactivateClickedHandler(playerProfile?.rewardsId)}
                        disabled={reactivatePlayerProcessing ? true : undefined}>
                Reactivate
                </Button>
              </span>
          </Alert>
        }
      </div>
    </div>
  );
}

export default PlayerProfileDetails;