import axios from "axios";

import {
  GET_PROGRAMS_REQUEST,
  GET_PROGRAMS_SUCCESS,
  GET_PROGRAMS_FAIL,
  GET_PROGRAMS_NETWORK_ERROR,
  GET_RESPONSE_STATUS,
} from "../constants/getOrderActions";
import { UPDATE_STATE } from "../constants/loginActions";

export const programRequest = () => async (dispatch, getState) => {
  const {
    loginDetails: { userInfo },
  } = getState();

  try {
    dispatch({
      type: GET_PROGRAMS_REQUEST,
    });

    // Build the UserClaim request
    const requestPayload = {
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      rewardsId: userInfo.rewardsId,
      ticketNum: userInfo.ticketNum,
    };

    // Send the offer and wait for a response from the server
    const { data, status } = await axios.post(
      process.env.REACT_APP_REDEEM_URL,
      requestPayload
    );

    // Update the Redux orderResult status with the status returned from the server
    dispatch({
      type: GET_RESPONSE_STATUS,
      payload: status,
    });

    // Notify Redux that the order request was successful
    dispatch({
      type: GET_PROGRAMS_SUCCESS,
      payload: { ...data, id: userInfo.id },
    });

    // Update the user's data in local storage keyed to the user's id to persist
    // the data across page refreshses
    localStorage.setItem("reward-" + userInfo.id, JSON.stringify(data));

    // Update the user data
    dispatch({
      type: UPDATE_STATE,
      payload: { ...data, id: userInfo.id },
    });
  } catch (error) {
    if (error.message === "Network Error") {
      dispatch({
        type: GET_PROGRAMS_NETWORK_ERROR,
        payload:
          "There was a problem communicating with the server. Please log out and try again in 15 minutes.",
      });
    } else {
      dispatch({
        type: GET_PROGRAMS_FAIL,
        payload:
          error.response ||
          "There was a problem communicating with the server. Please log out and try again in 15 minutes.",
      });
    }
  }
};

