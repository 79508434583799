import {
  GET_PROGRAMS_REQUEST,
  GET_PROGRAMS_SUCCESS,
  GET_PROGRAMS_FAIL,
  GET_PROGRAMS_NETWORK_ERROR,
  GET_RESPONSE_STATUS,
  RESET_ORDERS,
} from "../constants/getOrderActions";

const initialState = {
  networkFail: false,
  serverFail: false,
  orderLoading: false,
  orderError: "",
  orderStatus: "",
  newOrderData: {},
};

export const userOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROGRAMS_REQUEST:
      return {
        ...state,
        orderLoading: true,
        newOrderData: {},
      };
    case GET_PROGRAMS_SUCCESS:
      return {
        ...state,
        networkFail: false,
        serverFail: false,
        orderLoading: false,
        orderError: "",
        newOrderData: action.payload,
      };
    case GET_PROGRAMS_FAIL:
      return {
        ...state,
        serverFail: true,
        orderLoading: false,
        newOrderData: {},
        orderError: action.payload,
      };
    case GET_PROGRAMS_NETWORK_ERROR:
      return {
        ...state,
        networkFail: true,
        orderLoading: false,
        newOrderData: {},
        orderError: action.payload,
      };
    case GET_RESPONSE_STATUS:
      return { ...state, orderStatus: action.payload };
    case RESET_ORDERS:
      return { ...initialState };
    default:
      return { ...state };
  }
};
