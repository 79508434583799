import React from "react";
import { Button, Modal } from "react-bootstrap";

/**
 * A modal dialog that will pop up when first visiting the website.
 * This dialog can be used to display important announcements to
 * website visitors.
 *
 * @param show - Boolean state indicating if the modal should be displayed
 * @param handleStay - Function to call when the user clicks the "Stay In" button
 * @param handleLogout - Function to call when the user clicks the "Log Out" button
 * @returns {Element} - A modal dialog to display when the user is idle
 * @constructor
 */
const LoginOverLay = ({ show, handleClose }) => {
  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg" centered backdrop="static">
        <Modal.Header closeButton>
          <div className="login-modal-header">IMPORTANT CHANGES</div>
        </Modal.Header>
        <Modal.Body>
          <div className="login-modal-body">
            <p>
              We have made some changes to the gift card website. Please note the
              following:
            </p>
            <ul>
              <li>
                <strong>Use your ZIP code</strong> instead of the digital code/ticket number to log in. No need to
                worry about lost emails or vouchers.
              </li>
              <li>
                <strong>One website for all gift card claims.</strong> Fry's and all other gift card brands
                are now available on the same website.
              </li>
            </ul>
            <p>
              We hope these changes will make it easier for you to claim your gift cards and we thank you
              for your continued support as we work to improve your experience.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary login-modal-button" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LoginOverLay;
