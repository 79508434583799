export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';

export const GET_PROGRAMS_REQUEST = 'GET_PROGRAMS_REQUEST';
export const GET_PROGRAMS_SUCCESS = 'GET_PROGRAMS_SUCCESS';
export const GET_PROGRAMS_FAIL = 'GET_PROGRAMS_FAIL';
export const USER_REGISTERED_VALUES = 'USER_REGISTERED_VALUES';

export const USER_LOGOUT = 'USER_LOGOUT';
export const UPDATE_STATE = 'UPDATE_STATE';
export const EMPTY_UPDATED_STATE = 'EMPTY_UPDATED_STATE';

export const GET_ORDER_REQUEST = 'GET_ORDER_REQUEST';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAIL = 'GET_ORDER_FAIL';
