import React from "react";

import { Navbar, Button } from "react-bootstrap";

const BackOfficeHeader = ({ handleLogout }) => {
  return (
    <>
      <Navbar className="w-100 gcotg-user-navbar">
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            <Button
              variant="link"
              onClick={handleLogout}
              className="gcotg-navbar-link"
            >
              Log Out
            </Button>
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default BackOfficeHeader;
