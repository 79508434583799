import axios from "axios";
import moment from "moment";

import {
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTERED_VALUES,
  USER_LOGOUT,
  UPDATE_STATE,
  EMPTY_UPDATED_STATE,
} from "../constants/loginActions";
import { RESET_ORDERS } from "../constants/getOrderActions";

export const loginRequest =
  (rewardsId, dob, ticketNum = null, zipcode = null, id) => async (dispatch) => {
    try {
      dispatch({
        type: USER_REGISTER_REQUEST,
      });

      let responseData;
      // If zipcode is provided, use it as the third login field
      if(zipcode) {
        const response = await axios({
          url: process.env.REACT_APP_LOGIN_URL,
          method: "POST",
          data: {
            rewardsId: parseInt(rewardsId),
            dob: moment(dob, "MM/DD/YYYY").format("MM/DD/YYYY"),
            zipcode: zipcode,
          },
        });
        responseData = response.data;
      }
      // If ticketNum is provided, use it as the third login field
      else if(ticketNum) {
        const response = await axios({
          url: process.env.REACT_APP_LOGIN_URL,
          method: "POST",
          data: {
            rewardsId: parseInt(rewardsId),
            dob: moment(dob, "MM/DD/YYYY").format("MM/DD/YYYY"),
            ticketNum: ticketNum,
          },
        });
        responseData = response.data;
      }
      // Otherwise, if both zipcode and ticketNum are null, do not use the third field
      else {
        const response = await axios({
          url: process.env.REACT_APP_LOGIN_URL,
          method: "POST",
          data: {
            rewardsId: parseInt(rewardsId),
            dob: moment(dob, "MM/DD/YYYY").format("MM/DD/YYYY"),
          },
        });
        responseData = response.data;
      }

      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: { ...responseData, id },
      });
    } catch (error) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload:
          (error.response && error.response.data) ||
          "We are unable to log you in right now. Please refresh your browser and try again.",
      });
    }
  };
export const updatedValues =
  (rewardId, password, zipcode, ticketNum) => async (dispatch) => {
    const data = {
      rewardId: rewardId,
      password: password,
      zipcode: zipcode,
      ticketNum: ticketNum,
    };

    try {
      dispatch({
        type: USER_REGISTERED_VALUES,
        payload: data,
      });
    } catch (error) {}
  };

export const logOut = () => (dispatch, getState) => {
  dispatch({
    type: USER_LOGOUT,
  });
  dispatch({
    type: RESET_ORDERS,
  });
};

export const updateState = (userData) => (dispatch) => {
  dispatch({
    type: UPDATE_STATE,
    payload: userData,
  });
};
export const emptyUpdateState = () => (dispatch) => {
  dispatch({
    type: EMPTY_UPDATED_STATE,
  });
};
