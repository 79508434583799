import React from "react";
import { Button, Modal } from "react-bootstrap";

/**
 * A modal dialog that will pop up an "are you sure" message when the admin tries to
 * force a claim.
 *
 * @param show - Boolean state indicating if the modal should be displayed
 * @param offerId - The ID of the offer that the admin is trying to force a claim for
 * @param handleClose - Function to call when the admin clicks the "Nevermind" button
 * @param handleProceed - Function to call when the admin clicks the "Yes, force the claim" button
 * @returns {Element} - A modal dialog to display
 * @constructor
 */
const ForceClaimVerifyOverlay = ({ show, awardId, ticketId, handleClose, handleProceed }) => {
  return (
    <>
      <Modal show={show} onHide={handleClose} closebutton size="lg" centered backdrop="static">
        <Modal.Header>
          <div className="admin-modal-header">ARE YOU SURE?</div>
        </Modal.Header>
        <Modal.Body>
          <div className="admin-modal-body">
            <p>
              Are you sure you want to force a claim for ticket #{ticketId}?
            </p>
            <p>
              This action <strong>cannot be undone</strong> and will result in an
              immediate gift card order for the recipient. <strong>This action will be logged</strong>.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="admin-modal-footer">
          <Button className="btn btn-primary gcotg-button admin-modal-cancel-button" onClick={handleClose}>
            Nevermind, that was an accident
          </Button>
          <Button className="btn btn-primary gcotg-button-secondary admin-modal-proceed-button" onClick={handleProceed}>
            Yes, force the claim
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ForceClaimVerifyOverlay;
