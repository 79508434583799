import {Button, Col, Form, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {getPropertyList, sendFileData} from "../actions/backOfficeAction";
import {useDispatch, useSelector} from "react-redux";

const UploadFileForm = ({ idle }) => {

  const [propertySelected, setPropertySelected] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const propertyList = useSelector(
    (state) => state.backOfficeState.propertyList
  );

  const submitFileHandler = (e) => {
    e.preventDefault();

    // Only submit the file if a property has been selected
    // and if a file has actually been chosen
    if (propertySelected) {
      if (selectedFile != null) {

        // Submit the file
        dispatch(sendFileData(selectedFile, e));

        // Clear the file selection
        setSelectedFile(null);
        document.getElementById("ExcelFormControlFile").value = "";

      } else {
        setErrorMessage("You must select a file to upload");
      }
    } else {
      setErrorMessage(
        "You must select a property name for the file"
      );
    }
  };

  const propertySelectHandler = (e) => {
    e.preventDefault();
    setPropertySelected(true);
    setErrorMessage("");
  };

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setErrorMessage("");
  };

  // Upon first load, get the property list
  useEffect(() => {
    if (!idle) {
      dispatch(getPropertyList());
    }
  }, [dispatch, idle]);

  return (
    <div className="admin-screen-divider-div">
      <h3>File Upload</h3>
      <Form
        name="fileLoadForm"
        encType="multipart/form-data"
        action=""
        onSubmit={submitFileHandler}
        className="gcotg-form"
      >
        <Row>
          <Col>
            <div className="form-group backoffice-fileload-form-group">
              <label htmlFor="Property name for the file" className="backoffice-form-label">
                1. Property name for this load
              </label>
              {
                // If the propertyList has been received from the server,
                // display it as a pulldown list.
                // If not, just display a loading message.
                propertyList?.length > 0 ? (
                  <select
                    key={propertyList}
                    defaultValue="-1"
                    name="propertyId"
                    className="custom-select backoffice-pulldown-list"
                    onChange={propertySelectHandler}
                  >
                    <option value="-1" disabled>
                      Select the property name
                    </option>
                    {propertyList.map((propertyEntry) => (
                      <option
                        key={propertyEntry.propertyId}
                        value={propertyEntry.propertyId}
                      >
                        {propertyEntry.propertyName}
                      </option>
                    ))}
                  </select>
                ) : (
                  <div className="alert alert-danger" role="alert">
                    <p>
                      Property list not loaded from server. Please refresh
                      and try again.
                    </p>
                  </div>
                )
              }
            </div>
          </Col>
          <Col>
            <div className="form-group backoffice-fileload-form-group">
              <label htmlFor={"file"} className="backoffice-form-label">2. Select the file to upload</label>
              <input
                type="file"
                name="file"
                className="form-control-file gcotg-form-element"
                id="ExcelFormControlFile"
                onChange={(e) => onFileChange(e)}
              ></input>
            </div>
          </Col>
          <Col className="upload-button-column">
            <label htmlFor="Property name for the file" className="backoffice-form-label">
              3. Click the button to upload
            </label>
            <Button
              variant="primary"
              type="submit"
              className="upload-button gcotg-button"
            >
              Upload
            </Button>
          </Col>
          <div className="backoffice-error-messages">
            {errorMessage.length > 0 ? (
              <div className="alert alert-danger" role="alert">
                <p>
                  {errorMessage}
                </p>
              </div>
            ) : ""}
          </div>
        </Row>
      </Form>
    </div>
  );
};

export default UploadFileForm;