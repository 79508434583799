import {Button, Col, Form, Row} from "react-bootstrap";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {cancelOffer, getIncompleteOffers, retryOffer} from "../actions/backOfficeAction";
import PaginationComponent from "./PaginationComponent";

const AdvancedAdminTools = ({idle}) => {

  const [showTestOffers, setShowTestOffers] = useState(false);

  const dispatch = useDispatch();

  const getIncompleteOffersLoading = useSelector(
    (state) => state.backOfficeState.getIncompleteOffersLoading
  );
  const incompleteOffersList = useSelector(
    (state) => state.backOfficeState.incompleteOffersList
  );
  const getIncompleteOffersError = useSelector(
    (state) => state.backOfficeState.getIncompleteOffersError
  );

  const cancelOfferProcessing = useSelector(
    (state) => state.backOfficeState.cancelOfferProcessing
  );
  const retryOfferProcessing = useSelector(
    (state) => state.backOfficeState.offerRetryLoading
  );

  const cancelOfferClickedHandler = (awardId, showTestOffers) => {
    dispatch(cancelOffer(awardId, showTestOffers, incompleteOffersList.number, incompleteOffersList.size));
  }

  const retryOfferClickedHandler = (awardId, testData) => {
    dispatch(retryOffer(awardId, showTestOffers, incompleteOffersList.number, incompleteOffersList.size));
  }

  return (
    <div className="admin-screen-divider-div">
      <Row>
        <Col>
          <h3>Incomplete Offers</h3>
          {getIncompleteOffersLoading && <p>Loading offers...</p>}
          {getIncompleteOffersError && (
            <div className="alert alert-danger" role="alert">
              {getIncompleteOffersError}
            </div>
          )}
          <div className="show-test-offers-checkbox">
            <Form.Check
              type="checkbox"
              id="showTestOffersCheckbox"
              value={showTestOffers}
              label="Show test offers"
              onChange={(e) => setShowTestOffers(e.target.checked)}
            />
          </div>
          {incompleteOffersList?.content?.length === 0 && <p>No failed or pending offers found</p>}
          {incompleteOffersList?.content?.length > 0 && (
            <div>
              <table className="table table-striped problem-offers-table">
                <thead>
                <tr>
                  <th></th>
                  <th scope="col">Award ID</th>
                  <th scope="col">Ticket ID</th>
                  <th scope="col">Player ID</th>
                  <th scope="col">Guest Name</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Brand</th>
                  <th scope="col">Status</th>
                  <th scope="col">Last Updated</th>
                  <th scope="col">Swipe Date</th>
                  <th scope="col">Expire Date</th>
                  <th scope="col">Test Offer?</th>
                </tr>
                </thead>
                <tbody>
                {incompleteOffersList?.content?.map((offer) => (
                  <tr key={offer.awardId}>
                    <td>
                      {(offer.offerStatus === "CANCELLED" || offer.offerStatus === "FAILED") ?
                        <Button variant="primary" size="sm" className="gcotg-button"
                                onClick={() => retryOfferClickedHandler(offer.awardId, showTestOffers)}
                                disabled={retryOfferProcessing ? true : undefined}>
                          Retry
                        </Button> : ""}
                      {(offer.offerStatus === "PENDING") ?
                        <Button variant="primary" size="sm" className="gcotg-button"
                                onClick={() => cancelOfferClickedHandler(offer.awardId, showTestOffers)}
                                disabled={cancelOfferProcessing ? true : undefined}>
                          Cancel
                        </Button> : ""}
                    </td>
                    <td>{offer.awardId}</td>
                    <td>{offer.ticketId}</td>
                    <td>{offer.rewardsId}</td>
                    <td>
                      {offer.firstName} {offer.lastName}
                    </td>
                    <td>${offer.amount}</td>
                    <td>{offer.promotionName}</td>
                    <td>{offer.offerStatus}</td>
                    <td>{offer.lastUpdatedTs}</td>
                    <td>{offer.swipeDate}</td>
                    <td>{offer.expireDate}</td>
                    <td>{offer.testData ? "Yes" : "No"}</td>
                  </tr>
                ))}
                </tbody>
              </table>
              <PaginationComponent
                currentPage={incompleteOffersList.number}
                pageSize={incompleteOffersList.size}
                totalPages={incompleteOffersList.totalPages}
                pageFetchHandler={getIncompleteOffers}/>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AdvancedAdminTools;