import {
  BACKOFFICE_LOGIN_REQUEST,
  BACKOFFICE_LOGIN_SUCCESS,
  BACKOFFICE_LOGIN_FAIL,
  BACKOFFICE_LOGOUT,
  BACKOFFICE_REGISTER_SUCCESS,
  BACKOFFICE_REGISTER_FAIL,
} from "../constants/backOfficeAuthActions";

const initialState = {
  loading: false,
  loginSucceeded: false,
  registerSucceeded: false,
  token: "",
  error: "",
  message: "",
};

export const backOfficeAuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case BACKOFFICE_LOGIN_REQUEST:
      return { ...state, loading: true };
    case BACKOFFICE_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        loginSucceeded: true,
        token: action.payload,
      };
    case BACKOFFICE_LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        loginSucceeded: false,
        error: action.payload,
      };
    case BACKOFFICE_LOGOUT:
      return {};
    case BACKOFFICE_REGISTER_SUCCESS:
      return { ...state, registerSucceeded: true, message: action.payload };
    case BACKOFFICE_REGISTER_FAIL:
      return { ...state, registerSucceeded: false, message: action.payload };
    default:
      return { ...state };
  }
};
