import Loader from "./Loader";
import {Button} from "react-bootstrap";
import Moment from "react-moment";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {getBrandCatalog, refreshCatalog} from "../actions/backOfficeAction";
import PaginationComponent from "./PaginationComponent";

const BrandCatalogTools = () => {

  const dispatch = useDispatch();

  const catalogLoading = useSelector(
    (state) => state.backOfficeState.catalogLoading
  );
  const catalogMessage = useSelector(
    (state) => state.backOfficeState.catalogMessage
  );
  const catalogError = useSelector(
    (state) => state.backOfficeState.catalogError
  );
  const brandCatalogLoading = useSelector(
    (state) => state.backOfficeState.brandCatalogLoading
  );
  const brandCatalogList = useSelector(
    (state) => state.backOfficeState.brandCatalogList
  );
  const brandCatalogError = useSelector(
    (state) => state.backOfficeState.brandCatalogError
  );

  const catalogRefreshHandler = (e) => {
    e.preventDefault();
    dispatch(refreshCatalog());
  };

  return (
    <div className="admin-screen-divider-div">
      <div className="catalog-button-group">
        <p className="gcotg-info-message">
          The brand catalog is refreshed from the vendors every 24 hours. You can
          manually refresh the catalog by clicking the button below.
        </p>
        {catalogLoading && (
          <div>
            <Loader/>
            <p>Refreshing catalog ...</p>
          </div>
        )}
        {!catalogLoading && (
          <Button
            variant="primary"
            type="submit"
            onClick={catalogRefreshHandler}
            className="gcotg-button"
          >
            Refresh Catalog
          </Button>
        )}
      </div>
      {brandCatalogList && (
        <div className="brand-catalog-list-div">
          {brandCatalogList?.content?.length > 0 &&
            <h4 className="brand-catalog-list-title">
              Brand Catalog (last updated <Moment format="M/D/yyyy, hh:mm:ss A">
              {brandCatalogList?.content?.[0]?.lastUpdatedTs}</Moment>)
            </h4>}
          <table className="table table-striped">
            <thead>
            <tr className="brand-catalog-table">
              <th scope="col">Brand Name</th>
              <th scope="col">Display Name</th>
              <th scope="col">SKU</th>
              <th scope="col">Fixed?</th>
              <th scope="col">Min</th>
              <th scope="col">Max</th>
              <th scope="col">Vendor</th>
              <th scope="col">Digital?</th>
            </tr>
            </thead>
            <tbody>
            {brandCatalogList?.content?.map((brand) => (
              <tr key={brand.id} className="brand-catalog-table">
                <td>{brand.brandName}</td>
                <td>{brand.displayName}</td>
                <td>{brand.sku}</td>
                <td>{brand.fixed ? "Yes" : "No"}</td>
                <td>${brand.min}</td>
                <td>${brand.max}</td>
                <td>{brand.vendorName}</td>
                <td>{brand.digital ? "Yes" : "No"}</td>
              </tr>
            ))}
            </tbody>
          </table>
          <PaginationComponent
            currentPage={brandCatalogList.number}
            pageSize={brandCatalogList.size}
            totalPages={brandCatalogList.totalPages}
            pageFetchHandler={getBrandCatalog} />
        </div>
      )}
    </div>
  );
};

export default BrandCatalogTools;