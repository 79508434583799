import {Alert, Button, Col, Form} from "react-bootstrap";
import Moment from "react-moment";
import React, {useEffect, useState} from "react";
import {getLoads, toggleLoads} from "../actions/backOfficeAction";
import {useDispatch, useSelector} from "react-redux";
import PaginationComponent from "./PaginationComponent";

const LoadActivationTable = ({idle}) => {

  const [initialSwitches, setInitialSwitches] = useState({});
  const [changedSwitches, setChangedSwitches] = useState({});

  const dispatch = useDispatch();

  const getLoadsLoading = useSelector(
    (state) => state.backOfficeState.getLoadsLoading
  );
  const getLoadsList = useSelector(
    (state) => state.backOfficeState.getLoadsList
  );
  const getLoadsError = useSelector(
    (state) => state.backOfficeState.getLoadsError
  );

  const toggleLoadHandler = (e) => {
    e.preventDefault();
    // Call the toggleLoads action with the list of changed switches
    dispatch(toggleLoads(changedSwitches));
  };

  // When the user toggles a switch, update the changedSwitches state.
  // We only want the changedSwitches state to contain items that have
  // actually changed from the original state. We use two arrays to keep
  // track of this - initialSwitches to keep track of the original state
  // and changedSwitches to keep track of the changes. The changedSwitches state
  // only holds the switches that have changed from the original state.
  const activateSwitchChangeHandler = (loadId) => {
    setChangedSwitches((prev) => {
      // Nullish coalescing operator ?? returns the right-hand operand
      // if the left-hand operand is null or undefined.
      // This code toggles the switch value by negating the previous value if it exists
      // or negating the initial value if it doesn't.
      const newValue = !(prev[loadId] ?? initialSwitches[loadId]);
      // If the new value is back to the original value, remove it from the
      // changedSwitches state.
      if (newValue === initialSwitches[loadId]) {
        // Remove the loadId from the changedSwitches state
        const { [loadId]: _, ...rest } = prev;
        // Return the rest of the state minus the value that went
        // back to its original state
        return rest;
      }
      return {
        ...prev,
        [loadId]: newValue,
      };
    });
  };


  // If the contents of getLoadsList change, update the initialSwitches state
  useEffect(() => {
    // If the loads list gets updated from the server, set the initial switches
    // and reset the changed switches to nothing.
    if (getLoadsList) {
      setInitialSwitches(
        getLoadsList.content.reduce((acc, load) => {
          acc[load.loadId] = load.active;
          return acc;
        }, {})
      );
      setChangedSwitches({});
    }
  }, [getLoadsList]);

  // Upon first load, get the property list, load list, and failed offers
  useEffect(() => {
    if (!idle) {
      dispatch(getLoads());
    }
  }, [dispatch, idle]);

  return (
    <div className="admin-screen-divider-div">
      <Col>
        <div>
          <h3>Load Activation</h3>
          <div>
            <div className="backoffice-button-group">
              <Button
                variant="primary"
                type="submit"
                onClick={toggleLoadHandler}
                className="gcotg-button"
                disabled={Object.keys(changedSwitches).length === 0 ? true : undefined}
              >
                Activate/Deactivate Loads
              </Button>
              {Object.keys(changedSwitches).length > 0 && (
                <Alert key='warning' variant='warning' className="load-activation-warning">
                  Changes won't take effect until you click the Activate/Deactivate button
                </Alert>
              )}
            </div>
            {getLoadsLoading && <p>Retrieving loads...</p>}
            {getLoadsError && (
              <div className="alert alert-danger" role="alert">
                {getLoadsError}
              </div>
            )}
            {getLoadsList && (
              <div>
                <table className="table table-striped loads-table">
                  <thead>
                  <tr>
                    <th scope="col">Active?</th>
                    <th scope="col">Load ID</th>
                    <th scope="col">Load Date</th>
                    <th scope="col">Property Code</th>
                    <th scope="col">Total Dollars</th>
                    <th scope="col">Total Count</th>
                  </tr>
                  </thead>
                  <tbody>
                  {getLoadsList?.content.map((load) => (
                    <tr key={load.loadId}>
                      <td
                        className={(changedSwitches[load.loadId] !== undefined
                          && changedSwitches[load.loadId] !== initialSwitches[load.loadId]) ?
                          "switch-changed" : ""}>
                        <Form.Check
                          type="switch"
                          id={`custom-switch-${load.loadId}`}
                          className="custom-switch load-active-switch"
                          label={(changedSwitches[load.loadId] ?? load.active) ? "Active" : "Inactive"}
                          value={load.loadId}
                          checked={changedSwitches[load.loadId] ?? load.active}
                          onChange={() => activateSwitchChangeHandler(load.loadId)}
                        />
                      </td>
                      <td>{load.loadId}</td>
                      <td>
                        <Moment utc format="M/D/yyyy, hh:mm:ss A">
                          {load.loadTs}
                        </Moment>
                      </td>
                      <td>{load.propertyPrefix}</td>
                      <td>${load.total}</td>
                      <td>{load.count}</td>
                    </tr>
                  ))}
                  </tbody>
                </table>
                <PaginationComponent
                  currentPage={getLoadsList.number}
                  pageSize={getLoadsList.size}
                  totalPages={getLoadsList.totalPages}
                  pageFetchHandler={getLoads} />
              </div>
            )}
          </div>
          {getLoadsList?.length === 0 && <p>No loads found</p>}
        </div>
      </Col>
    </div>
  )
};

export default LoadActivationTable;