import React, {useState} from "react";
import {useDispatch} from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import UploadFileForm from "../components/UploadFileForm";
import {Container, Row, Tab, Tabs} from "react-bootstrap";
import {
  getBrandCatalog, getIncompleteOffers, getLoads,
  resetBackOffice,
} from "../actions/backOfficeAction";
import {ToastContainer} from "react-toastify";
import {logout} from "../actions/backOfficeAuthAction";
import BackOfficeHeader from "../components/BackOfficeHeader";
import useIdleTimeout from "../components/useIdleTimeout";
import IdleOverLay from "../components/IdleOverlay";
import LoadActivationTable from "../components/LoadActivationTable";
import PlayerTools from "../components/PlayerTools";
import BrandCatalogTools from "../components/BrandCatalogTools";
import AdvancedAdminTools from "../components/AdvancedAdminTools";

const BackOfficeScreen = () => {

  // Create state variable to show/hide the idle timeout overlay
  const [showIdleOverlay, setShowIdleOverlay] = useState(false);

  const dispatch = useDispatch();

  // Shows the idle overlay warning the user that they will be logged out soon
  const showIdlePrompt = () => {
    setShowIdleOverlay(true);
  };

  // Closes the overlay if the user clicks on the "Stay In" button
  const handleStay = () => {
    setShowIdleOverlay(false);
    idleTimer.reset();
  };

  // Logs the user out if they select to log out
  const logOutHandler = (e) => {
    setShowIdleOverlay(false);
    dispatch(logout());
    dispatch(resetBackOffice());
  };

  // Use the idle timeout hook to track user activity
  const {idle, idleTimer} = useIdleTimeout({
    promptBeforeIdle: showIdlePrompt,
    idleTime: process.env.REACT_APP_ADMIN_IDLE_TIMEOUT_MINUTES, // User is logged out after idleTime minutes of inactivity
    doWhenIdle: logOutHandler,
  });

  // The brand catalog tab needs to fetch the brand catalog when it is selected
  const tabSelectHandler = (eventKey) => {
    if (eventKey === "brand-catalog") {
      dispatch(getBrandCatalog());
    }
    else if (eventKey === "advanced") {
      dispatch(getIncompleteOffers(null, null, false));
    }
    else if (eventKey === "file-load") {
      dispatch(getLoads());
    }
  }

  return (
    <Container fluid className="no-padding">
      <BackOfficeHeader handleLogout={logOutHandler}/>
      <div className="card card-container backoffice-screen-card-container">
        <ToastContainer className="Toastify__toast-container--top-right backoffice-toast-container"/>
        <div>
          {showIdleOverlay && (
            <IdleOverLay
              show={showIdleOverlay}
              handleStay={handleStay}
              handleLogout={logOutHandler}
            />
          )}
        </div>
        <h2 className="backoffice-title">Back Office Dashboard</h2>
        <Tabs
          defaultActiveKey="file-load"
          className="back-office-tabs"
          onSelect={tabSelectHandler}
        >
          <Tab eventKey="file-load" title="File Load">
            <Container fluid className="backoffice-tab-container">
              <Row>
                <UploadFileForm idle={idle}/>
              </Row>
              <Row>
               <LoadActivationTable idle={idle}/>
              </Row>
            </Container>
          </Tab>
          <Tab eventKey="playertools" title="Player Tools">
            <Container fluid className="backoffice-tab-container">
              <Row>
                <PlayerTools/>
              </Row>
            </Container>
          </Tab>
          <Tab eventKey="brand-catalog" title="Brand Catalog">
            <Container fluid className="backoffice-tab-container">
              <Row>
                <BrandCatalogTools/>
              </Row>
            </Container>
          </Tab>
          <Tab eventKey="advanced" title="Advanced">
            <Container fluid className="backoffice-tab-container">
              <AdvancedAdminTools idle={idle}/>
            </Container>
          </Tab>
        </Tabs>
      </div>
    </Container>
  );
};

export default BackOfficeScreen;
