import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";

const AuthenticatedRoute = props => {

    // Read Redux state
	const backofficeAuthDetails = useSelector((state) => state.backofficeAuthState);
    const { loginSucceeded } = backofficeAuthDetails;

    // If logged in, display the backoffice screen
    if(loginSucceeded) {
        return <Route {...props} />;
    }
    
    // Otherwise if not logged in, redirect to login page
    else {
        return <Redirect to="/backofficelogin" />;
    }

}

export default AuthenticatedRoute