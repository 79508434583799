import React from "react";

const PendingOfferTable = ({ userInfo }) => {
  const { giftcardListPending } = userInfo;

  return (
    <div className="gcotg-table pending-table">
      <div className="pending-table-header">
        <h3>
          Pending Offers
        </h3>
        <p className="pending-offers-message">
          These offers are still processing. Please check back again later.
        </p>
      </div>
      <table className="table">
        <thead className="tablehead">
        <tr>
          <th scope="col">Swipe Date</th>
          <th scope="col">Offer Code</th>
          <th scope="col">Brand</th>
          <th scope="col">Amount</th>
        </tr>
        </thead>

        <tbody>
        {giftcardListPending?.map((giftCard) => (
            <tr key={giftCard.awardId}>
              <td>{giftCard.swipeDate}</td>
              <td>
                {giftCard.ticketId ? giftCard.ticketId : "Pending"}
              </td>
              <td>{giftCard.promotionName}</td>
              <td> ${giftCard.price}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PendingOfferTable;
