import {
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTERED_VALUES,
  USER_LOGOUT,
  UPDATE_STATE,
  EMPTY_UPDATED_STATE,
} from "../constants/loginActions";

const initialState = {
  networkFail: false,
  loading: false,
  userInfo: "",
  error: "",
  orderStatus: "",
  userValues: "",
};

export const userLoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { ...state, loading: true };
    case USER_REGISTER_SUCCESS:
      return {
        ...state,
        networkFail: false,
        loading: false,
        userInfo: action.payload,
        error: "",
      };
    case UPDATE_STATE:
      return {
        ...state,
        networkFail: false,
        loading: false,
        userInfo: action.payload,
      };
    case EMPTY_UPDATED_STATE:
      return {};
    case USER_REGISTER_FAIL:
      return {
        ...state,
        networkFail: true,
        loading: false,
        error: action.payload,
      };
    case USER_REGISTERED_VALUES:
      return { ...state, userValues: action.payload };
    case USER_LOGOUT:
      return { ...initialState };
    default:
      return { ...state };
  }
};
