import { useState } from "react";
import { useIdleTimer } from "react-idle-timer";


/**
 * Hook to track user inactivity. Uses the react-idle-timer library to track
 * user activity and call a function when the user has been idle for a specified
 * amount of time.
 *
 * @param promptBeforeIdle - Function to call before the idle time expires. This
 * can be used to display a warning that the timer is expiring soon.
 * @param idleTime - Number of minutes of idle time before the timer expires.
 * @param doWhenIdle - Function to call when the idleTime has elapsed.
 * @returns - Three functions to control the idle timer. isIdle returns a boolean
 * indicating if the user is idle, setIdle is used to set the idle state to true or false,
 * and idleTimer is the idle timer object itself which can be used to call
 * the reset() function to reset the idle timer.
 */
const useIdleTimeout = ({ promptBeforeIdle, idleTime = 5, doWhenIdle }) => {
  // Convert minutes to milliseconds for calling useIdleTimer
  const idleTimeout = idleTime * 1000 * 60;
  // State to track if the user is idle
  const [isIdle, setIdle] = useState(false);

  // Use the idle timer to track user activity
  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    // If the idleTimeout is greater than 2 minutes, prompt the user with 2 minutes to spare
    // before the timeout expires. Otherwise prompt the user when 1/3 of the idleTimeout remains.
    promptBeforeIdle: idleTimeout > 120_000 ? 120_000 : idleTimeout / 3,
    onPrompt: promptBeforeIdle, // Display a prompt to the user when they are idle
    onIdle: doWhenIdle, // Function to call when the user is idle
    debounce: 500,
  });

  return { isIdle, setIdle, idleTimer };
};

export default useIdleTimeout;
