import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
//import FormContainer from "../components/FormContainer";
import { loginRequest, updatedValues } from "../actions/loginAction";
import InputMask from "react-input-mask";
//import logo from "../static/CR.png";
import Loader from "../components/Loader";
import CryptoJS from "crypto-js";
import LoginOverLay from "../components/LoginOverlay";

const LoginScreen = ({ history }) => {
  // Create state variables to hold the login information
  const [dob, setDob] = useState("");
  const [rewardId, setRewardId] = useState("");
  const [ticketNum, setTicketNum] = useState("");
  const [zipcode, setZipcode] = useState("");
  const dispatch = useDispatch();

  // Modal login overlay for displaying messages to website visitors
  const [showLoginOverlay, setShowLoginOverlay] = useState(process.env.REACT_APP_SHOW_LOGIN_MODAL === 'true');
  const handleClose = () => {
    setShowLoginOverlay(false);
  };

  // Get the loginDetails from the Redux store and destructure the loading and error properties
  const loginDetails = useSelector((state) => state.loginDetails);
  const { loading, error } = loginDetails;

  useEffect(() => {
    // If the Redux store contains login details (returned from the server) and no errors,
    // it means the login was successful. Open the user home page.
    if (loginDetails.userInfo && !loginDetails.error) {
      const { userInfo } = loginDetails;
      history.push(`./userhome/${userInfo.id}`);
    }
    // clear storage when tab is close, ex: when we close
    // tab Redux will become null hence clear local storage
    if (!loginDetails.userInfo) {
      localStorage.clear();
    }

    localStorage.removeItem("ex-claimrewards");
  }, [loginDetails, history, error]);

  // Handler for the sign-in button. Dispatches the loginRequest action,
  // which ultimately sends the login request to the server
  const onSubmitHandler = (e) => {
    e.preventDefault();

    // If we have all sign-in data, dispatch the loginRequest action
    const userId = rewardId;
    const id = "" + CryptoJS.SHA256(userId);
    // If the third login field is ticketNum (NorCal), use the ticketNum field
    if (process.env.REACT_APP_THIRD_LOGIN_FIELD === "ticketNum" && dob && rewardId && ticketNum) {
      dispatch(loginRequest(rewardId, dob, ticketNum, null, id));
    }
    // If the third login field is zipcode (Ak-Chin), use the zipcode field
    else if (process.env.REACT_APP_THIRD_LOGIN_FIELD === "zipcode" && dob && rewardId && zipcode) {
      dispatch(loginRequest(rewardId, dob, null, zipcode, id));
    }
    // If the third login field is none (HRSC), do not use the third field
    else if (process.env.REACT_APP_THIRD_LOGIN_FIELD === "none" && dob && rewardId) {
      dispatch(loginRequest(rewardId, dob, null, null, id));
    }

    // Update the Redux store with the user's login details
    dispatch(updatedValues(rewardId, dob, zipcode, ticketNum));
  };

  return (
    <div className="gcotg-login-top-level">
      <Container fluid>
        <Row>
          <Col className="login-content-box">
            <div>
                <LoginOverLay
                  show={showLoginOverlay}
                  handleClose={handleClose}
                />
            </div>
            <img src={process.env.REACT_APP_PROPERTY_LOGO} alt="Logo" className="main-logo"/>
            {loading && <Loader/>}
            <h2 className="login-screen-title">Digital Gift Card Claims</h2>
            <p className="login-screen-message">
              {process.env.REACT_APP_LOGIN_MESSAGE}
            </p>
            <Form onSubmit={(e) => onSubmitHandler(e)} className="gcotg-form">
              <Form.Group
                controlId="formBasicEmail"
                className="gcotg-form-group"
              >
                <Form.Label className="gcotg-form-label">
                  Rewards #
                </Form.Label>
                <Form.Control
                  value={rewardId}
                  placeholder="Rewards Number"
                  onChange={(e) => setRewardId(e.target.value)}
                  className="form-control gcotg-form-element"
                />
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Label className="gcotg-form-label">
                  Birth Date(MM/DD/YYYY)
                </Form.Label>
                <Form.Control
                  as={InputMask}
                  mask="99/99/9999"
                  placeholder="mm/dd/yyyy"
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                  className="form-control gcotg-form-element"
                />
              </Form.Group>
              {process.env.REACT_APP_THIRD_LOGIN_FIELD === "ticketNum" && (
                <Form.Group controlId="formBasicTicket">
                  <Form.Label className="gcotg-form-label">
                    Digital Code or Ticket #
                  </Form.Label>
                  <Form.Control
                    placeholder="Ticket #"
                    value={ticketNum}
                    onChange={(e) => setTicketNum(e.target.value)}
                    className="form-control gcotg-form-element"
                  />
                </Form.Group>
              )}
              {process.env.REACT_APP_THIRD_LOGIN_FIELD === "zipcode" && (
                <Form.Group controlId="formBasicZipcode">
                  <Form.Label className="gcotg-form-label">ZIP Code</Form.Label>
                  <Form.Control
                    placeholder="ZIP Code"
                    value={zipcode}
                    onChange={(e) => setZipcode(e.target.value)}
                    className="form-control gcotg-form-element login-zipcode-form-element"
                  />
                </Form.Group>
              )}
              <Button variant="primary" type="submit" className="gcotg-button">
                SIGN IN
              </Button>
            </Form>
            {error && (
              <div className="gcotg-error-message">
                <div role="alert">{error}</div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LoginScreen;
