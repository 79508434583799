import React from "react";

import {Navbar, NavItem, NavLink, Nav} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { logOut } from "../actions/loginAction";
import { useHistory } from "react-router-dom";

const Header = ({ id }) => {
  const loginDetails = useSelector((state) => state.loginDetails);

  const { userInfo } = loginDetails;

  const history = useHistory();

  const dispatch = useDispatch();

  const logOutHandler = () => {
    if (id) {
      localStorage.removeItem("reward-" + userInfo.id);
      localStorage.removeItem("orders-" + userInfo.id);
      localStorage.removeItem("ex-" + id);
    }

    dispatch(logOut());
    history.push("/");
  };
  return (
    <>
      <Navbar className="w-100 gcotg-user-navbar">
        <Navbar.Brand>
          <img
            src={process.env.REACT_APP_PROPERTY_LOGO}
            className="d-inline-block align-middle navbar-logo"
            alt="Main application logo"
          />
        </Navbar.Brand>
        <Navbar.Collapse className="justify-content-end">
          <Nav variant="underline">
            <NavItem>
              <NavLink onClick={logOutHandler} className="gcotg-navbar-link">
                Sign Out
              </NavLink>
            </NavItem>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default Header;
