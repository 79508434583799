// Sets up axios to include the token in any subsequent request headers
import axios from "axios";

export const setupAxiosInterceptors = (token) => {
  const tokenInterceptor = axios.interceptors.request.use((config) => {
    if (token) {
      config.headers.authorization = "Bearer " + token;
    }
    config.headers["sigmar-property-prefix"] = process.env.REACT_APP_PROPERTY_PREFIX;
    return config;
  });

  // Save the interceptor ID in local storage for later removal
  localStorage.setItem("TokenInterceptor", JSON.stringify(tokenInterceptor));
};