import axios from "axios";
import {setupAxiosInterceptors} from "./AxiosConfigService";

// Registration request for new user
const register = async (username, password) => {
  const response = await axios({
    url: process.env.REACT_APP_BACK_OFFICE_REGISTER_URL,
    method: "POST",
    data: {
      username: username,
      password: password,
    },
  });

  return { ...response };
};

// Login request
const login = async (username, password) => {
  const response = await axios({
    url: process.env.REACT_APP_BACK_OFFICE_LOGIN_URL,
    method: "POST",
    data: {
      username: username,
      password: password,
    },
  });

  const authHeader = response.headers["authorization"];
  const token = authHeader.replace("Bearer ", "");

  // Set the token for subsequent requests
  setupAxiosInterceptors(token);

  return token;
};

// Logs out
const logout = () => {
  // Get the stored token from local storage
  //const tokenInterceptor = localStorage.getItem("TokenInterceptor");

  // Remove the token header from axios interceptor
  axios.interceptors.request.eject("TokenInterceptor");

  // Remove the interceptor from local storage
  localStorage.removeItem("TokenInterceptor");
};

const backofficeService = {
  register,
  login,
  logout,
};
export default backofficeService;
