import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { userLoginReducer } from "../reducer/userLoginReducer";
import { userOrdersReducer } from "../reducer/orderReducer";
import { backOfficeReducer } from "../reducer/backOfficeReducer";
import { backOfficeAuthReducer } from "../reducer/backOfficeAuthReducer";

const reducer = combineReducers({
  loginDetails: userLoginReducer,
  orderResults: userOrdersReducer,
  backOfficeState: backOfficeReducer,
  backofficeAuthState: backOfficeAuthReducer,
});

const userLoginStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;
const orderStorage = localStorage.getItem("orders")
  ? JSON.parse(localStorage.getItem("orders"))
  : null;
const middleware = [thunk];
const initialState = {
  loginDetails: { userInfo: userLoginStorage },
  orderResults: { orders: orderStorage },
};

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);
export default store;
