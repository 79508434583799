/* Below order of css imports is important
    1. Bootstrap css
    2. App.css (imported AFTER bootstrap css to override bootstrap css if required)
    3. Component css (if any - to override App.css if required)
  */
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/App.css";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import LoginScreen from "./screens/LoginScreen";
import UserHomeScreen from "./screens/UserHomeScreen";
import BackOfficeScreen from "./screens/BackOfficeScreen";
import BackOfficeLogin from "./screens/BackOfficeLogin";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import Register from "./screens/Register";
import {setupAxiosInterceptors} from "./services/AxiosConfigService";

// Initialize axios interceptors
setupAxiosInterceptors();

const App = ({ history }) => {
  return (
    <div className="gcotg-top-level-app">
      <Router history={history}>
        <Switch>
          <Route path="/" component={LoginScreen} exact />
          <Route path="/userhome/:id" component={UserHomeScreen} />
          <Route path="/backofficelogin" component={BackOfficeLogin} />
          <Route path="/register" component={Register} />
          <AuthenticatedRoute path="/backoffice" component={BackOfficeScreen} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
