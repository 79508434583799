import {Alert, Button, Form, InputGroup} from "react-bootstrap";
import Loader from "./Loader";
import Moment from "react-moment";
import ForceClaimVerifyOverlay from "./ForceClaimVerifyOverlay";
import React, {useState} from "react";
import {forceClaim, getPlayerOffers, getPlayerProfile} from "../actions/backOfficeAction";
import {useDispatch, useSelector} from "react-redux";
import PaginationComponent from "./PaginationComponent";
import PlayerProfileDetails from "./PlayerProfileDetails";

const PlayerTools = () => {

  const [playerId, setPlayerId] = useState("");
  const [showForceClaimVerifyOverlay, setShowForceClaimVerifyOverlay] = useState(false);
  const [forceClaimAwardId, setForceClaimAwardId] = useState("");
  const [forceClaimTicketId, setForceClaimTicketId] = useState("");
  const dispatch = useDispatch();

  const forceClaimProcessing = useSelector(
    (state) => state.backOfficeState.forceClaimLoading
  );
  const forceClaimResponse = useSelector(
    (state) => state.backOfficeState.forceClaimResponse
  );
  const forceClaimError = useSelector(
    (state) => state.backOfficeState.forceClaimError
  );

  const playerOffersLoading = useSelector(
    (state) => state.backOfficeState.playerOffersLoading
  );
  const playerOffersList = useSelector(
    (state) => state.backOfficeState.playerOffersList
  );
  const playerOffersError = useSelector(
    (state) => state.backOfficeState.playerOffersError
  );

  const playerProfileLoading = useSelector(
    (state) => state.backOfficeState.playerProfileLoading
  );
  const playerProfile = useSelector(
    (state) => state.backOfficeState.playerProfile
  );
  const playerProfileError = useSelector(
    (state) => state.backOfficeState.playerProfileError
  );

  // Sets the value of the player ID to search offers for
  function playerIdChangeHandler(value) {
    setPlayerId(value);
  }

  function searchPlayerOffersHandler() {
    // Only dispatch the action if the player ID is not empty
    if (playerId) {
      // Get the player profile
      dispatch(getPlayerProfile(playerId));

      // Get the player offers
      dispatch(getPlayerOffers(playerOffersList?.number ? playerOffersList.number : 0,
        playerOffersList?.size ? playerOffersList.size : process.env.REACT_APP_DEFAULT_PAGE_SIZE,
        { playerId }));
    }
  }

  // Called when the admin clicks the "Force Claim" button in the row of an offer
  function forceClaimClickedHandler(awardId, ticketId) {
    // Set the offer ID in the state
    setForceClaimAwardId(awardId);
    setForceClaimTicketId(ticketId);
    // Show the "are you sure" overlay
    setShowForceClaimVerifyOverlay(true);
  }

  // Function handling the "nevermind" button on the force claim overlay.
  function cancelForceClaimHandler() {
    setShowForceClaimVerifyOverlay(false);
    setForceClaimAwardId("");
    setForceClaimTicketId("");
  }

  // Function handling the "Yes, force the claim" button on the force claim overlay.
  function proceedForceClaimHandler() {
    setShowForceClaimVerifyOverlay(false);
    // dispatch the force claim action here
    dispatch(forceClaim(forceClaimAwardId, playerId));
  }

  // Calculate the cutoff date for when it is okay to display the "Force Claim" button.
  // If the offer expired more than the force claim cutoff days defined in the .env file,
  // the button will be disabled.
  const forceClaimCutoffDate = new Date();
  forceClaimCutoffDate.setDate(forceClaimCutoffDate.getDate() -
    process.env.REACT_APP_BACK_OFFICE_FORCE_CLAIM_CUTOFF_DAYS);

  return (
    <div className="admin-screen-divider-div">
      <h3>Search By Player ID</h3>
      <InputGroup className="mb-3 player-id-search-input">
        <Form.Control
          placeholder="Player ID"
          aria-label="Player ID"
          aria-describedby="basic-addon2"
          onChange={(e) => playerIdChangeHandler(e.target.value)}
        />
        <Button variant="outline-secondary" id="button-addon2" onClick={searchPlayerOffersHandler}>
          Search
        </Button>
      </InputGroup>
      {forceClaimProcessing &&
        <Alert key='warning' variant='warning' className="load-activation-warning">
          <Loader/>
          Please wait while the offer is being force claimed...
        </Alert>
      }
      {playerProfile &&
        <PlayerProfileDetails playerProfile={playerProfile}/>
      }
      {playerOffersLoading && <p>Searching for offers...</p>}
      {playerOffersList?.content?.length === 0 &&
        <Alert key='warning' variant='warning' className="load-activation-warning">
          Nothing found for player ID {playerId}
        </Alert>
      }
      {playerOffersList?.content?.length > 0 && (
        <div className="player-offer-list-div">
          {playerOffersList?.content?.length > 0 &&
            <h4 className="player-offer-name-title">Offers for{" "}
              {playerOffersList?.content?.[0]?.firstName}{" "}{playerOffersList?.content?.[0]?.lastName}
            </h4>}
          <table className="table table-striped loads-table">
            <thead>
            <tr className="player-offer-table">
              <th></th>
              <th scope="col">Award ID</th>
              <th scope="col">Ticket ID</th>
              <th scope="col">Property Code</th>
              <th scope="col">Amount</th>
              <th scope="col">Brand</th>
              <th scope="col">Status</th>
              <th scope="col">Date Claimed</th>
              <th scope="col">Date Swiped</th>
              <th scope="col">Expiration Date</th>
            </tr>
            </thead>
            <tbody>
            {playerOffersList?.content?.map((offer) => (
              <tr key={offer.awardId} className="player-offer-table">
                <td>
                  {(offer.offerStatus === "UNCLAIMED" || offer.offerStatus === "FAILED")
                  && new Date(offer.expireDate) >= forceClaimCutoffDate ?
                    <Button variant="primary" size="sm" className="gcotg-button"
                            onClick={() => forceClaimClickedHandler(offer.awardId, offer.ticketId)}
                            disabled={forceClaimProcessing ? true : undefined}>
                      Force Claim
                    </Button> : ""}
                </td>
                <td>{offer.awardId}</td>
                <td>{offer.ticketId}</td>
                <td>{offer.propertyPrefix}</td>
                <td>${offer.amount}</td>
                <td>{offer.promotionName}</td>
                <td>{offer.offerStatus}</td>
                <td>
                  {offer.redeemTs ?
                    <Moment utc format="M/D/yyyy, hh:mm:ss A">
                      {offer.redeemTs}
                    </Moment> : ""}
                </td>
                <td>
                  <Moment utc format="M/D/yyyy, hh:mm:ss A">
                    {offer.swipeDate}
                  </Moment>
                </td>
                <td className={(offer.offerStatus === "UNCLAIMED" &&
                  new Date(offer.expireDate) < new Date()) ?
                  "admin-offer-expired" : "admin-offer-valid"}>
                  <Moment utc format="M/D/yyyy, hh:mm:ss A">
                    {offer.expireDate}
                  </Moment>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
          <PaginationComponent
            currentPage={playerOffersList.number}
            pageSize={playerOffersList.size}
            totalPages={playerOffersList.totalPages}
            pageFetchHandler={getPlayerOffers}
            playerId={playerId}/>
        </div>
      )}
      <div>
        {showForceClaimVerifyOverlay && (
          <ForceClaimVerifyOverlay
            show={showForceClaimVerifyOverlay}
            awardId={forceClaimAwardId}
            ticketId={forceClaimTicketId}
            handleClose={cancelForceClaimHandler}
            handleProceed={proceedForceClaimHandler}
          />
        )}
      </div>
    </div>
  );
};

export default PlayerTools;